/*
 Selector-esque
 * Using useCallback for stability so we can take advantage of react-queries memoization
*/
import {useCallback} from 'react';
import {BreakDown, BreakdownItem, Cart, CartPlan} from 'types/cart.types';
import useCartCache from '@store/queries/cart/useCart.cache';
import {getKeysInObject} from '@utils/object';
import {User} from 'types/user.types';
import get from 'lodash/get';

/*
 * useCartByKeySelector
 * - Gets a key in the Cart cache.
 *
 * If you want just one key value from the Cart cache.
 * const id = useCartByKeySelector('id');
 * const planId = useCartByKeySelector('plan.id');
 * */
export const useCartByKeySelector = (key: string) => useCartCache(useCallback((data: User) => get(data, key), [key]));

/*
 * If you want more than one key of values from the Cart cache.
 * const { data: { id, partners } } = useCartByKey(['id', 'partners']);
 * */
export const useCartByKeysSelector = (keys: string[]) =>
  useCartCache(
    useCallback(
      (data: Cart) => {
        const objectByKeys = getKeysInObject<string[], Cart>(keys, data);

        return objectByKeys;
      },
      [keys],
    ),
  );

/*
 * Get cart count
 * */
export const useCartCountSelector = () => useCartCache<number>((data: Cart): number => (data?.items?.length || 0) + (data?.plan?.id ? 1 : 0));

/**
 * Is our cart eligible for Affirm
 *
 * @returns {Cart.affirmEligible}
 */
export const useCartAffirmEligibleSelector = () => useCartCache<boolean>((data: Cart) => data.affirmEligible);

/*
 * Get cart plan. We could create one for every key in "plan", that seems overkill.
 * */
export const useCartPlanSelector = () => useCartCache<CartPlan>((data: Cart): CartPlan => data?.plan ?? ({} as CartPlan));

/**
 * Get single service determinant
 *
 * return {boolean}
 */
export const useCartSingleServiceSelector = () => useCartCache<boolean>((data: Cart): boolean => data?.singleService || false);

/**
 * Get cart breakdown
 */
// export const useCartBreakdownSelector = () => useCartCache<BreakdownItem>((data: Cart): BreakdownItem => data?.breakdown || {});
export const useCartBreakdownSelector = () => useCartCache<BreakDown>((data: Cart): BreakdownItem => data?.breakdown || {});

/*
  example selector using multiple selectors

  export const useTestSelector = () => {
    const loaded = useIsUserLoadedSelector();
    const count = useCartCountSelector();

    return useMemo(() => loaded ? `Homie loaded with ${count} items` : `NOT LOADED BUT HAS ${count} items`, [A, B])

  }
*/
